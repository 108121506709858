import { Modal, ModalCloseReason } from "@bokio/components/Modal/Modal";
import { LoadingProgress } from "@bokio/elements/Loading";
import { SettingsLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { Day } from "@bokio/mobile-web-shared/core/model/model";
import { toEnvelopeEndpoint } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useUserMemberships } from "@bokio/shared/state/requests";

import { RenderApi } from "../RenderApi/RenderApi";
import { InvalidPermissionsView } from "./AddFiscalYearModalViews/InvalidPermissionsView";
import { RedirectView } from "./AddFiscalYearModalViews/RedirectView";
import { CreateFiscalYearForm } from "./components/CreateFiscalYearForm/CreateFiscalYearForm";

import type { ModalProps } from "@bokio/components/Modal/Modal";
import type * as React from "react";

type FiscalYearsDto = m.Bokio.Services.FiscalYears.FiscalYearsDto;
import FiscalYearStatus = m.Core.FiscalYearStatus;
type UserAccess = m.Settings.Controllers.UserAccess;
interface AddFiscalYearModalProps {
	userPermissions: UserAccess;
}

export const AddFiscalYearModal: React.FC<AddFiscalYearModalProps & ModalProps> = props => {
	const { userPermissions } = props;
	const { memberships } = useUserMemberships();
	const { companyInfo } = useCompanyInfo();
	const lang = SettingsLangFactory();

	if (memberships === undefined) {
		return <LoadingProgress />;
	}

	const checkYears = (fiscalYears: FiscalYearsDto): boolean => {
		const today = Day.today();
		if (fiscalYears.NextYear.StartDate <= today && today <= fiscalYears.NextYear.EndDate) {
			return true;
		}
		if (fiscalYears.PreviousYear.StartDate <= today && today <= fiscalYears.PreviousYear.EndDate) {
			return true;
		}
		return false;
	};

	const canCreateFiscalYear = userPermissions.CompanySettings;
	if (!canCreateFiscalYear && companyInfo.FiscalYearStatus === m.Core.FiscalYearStatus.PromptToCreateNextYear) {
		return null;
	}

	const belongsToMultipleCompanies = !!(memberships && memberships.companies.length > 1);
	const title =
		companyInfo.FiscalYearStatus === FiscalYearStatus.PromptToCreateNextYear
			? lang.AddFiscalYearModal_CreateUpcomming_Header
			: lang.FiscalYears_Create_Title;
	return (
		<Modal alwaysMounted={true} {...props} title={title} testId="AddFiscalYearModal">
			{canCreateFiscalYear ? (
				<RenderApi<[string], FiscalYearsDto, m.SimpleError>
					endpoint={toEnvelopeEndpoint(proxy.Bokio.Accounting.Web.Settings.FiscalYearsController.Index.Get)}
					parameters={[companyInfo.Id]}
					whenError="useDefault"
					whenLoading="progress"
				>
					{({ data }) => {
						// If we aren't certain which fiscal year to create, we should redirect them to the settings where they have more control
						const canDeterminYearToCreate =
							companyInfo.FiscalYearStatus === FiscalYearStatus.PromptToCreateNextYear || checkYears(data);
						if (canDeterminYearToCreate) {
							return (
								<CreateFiscalYearForm
									belongsToMultipleCompanies={belongsToMultipleCompanies}
									fiscalYears={data}
									onFiscalCreated={() => props.onClose(ModalCloseReason.Button)}
								/>
							);
						} else {
							return <RedirectView showSelectCompanyLink={belongsToMultipleCompanies} companyId={companyInfo.Id} />;
						}
					}}
				</RenderApi>
			) : (
				<InvalidPermissionsView showSelectCompanyLink />
			)}
		</Modal>
	);
};
