import { Route } from "react-router";

import { LoadingPage } from "@bokio/elements/Loading/LoadingPage";
import { BackOfficeLangFactory, GeneralLangFactory } from "@bokio/lang";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getRoute } from "@bokio/shared/route";
import { useAgencyStatus, useUser } from "@bokio/shared/state/requests";
import { withBooleanFilter } from "@bokio/utils/arrayUtils";

import Layout from "../Layout/Layout";
import Sidebar from "../Sidebar/Sidebar";
import TopBar, { TopBarMode } from "../TopBar/TopBar";
import { AgencyCounterType } from "./AgencyMenuCounter";

import type { MenuSection } from "../Menu/utils/MenuHelper";
import type { RouteProps } from "react-router";

const AgencyRouteContent = (props: RouteProps) => {
	const { isLoadingUser } = useUser();
	const { isLoadingAgency, agencyStatus } = useAgencyStatus();

	return isLoadingUser || isLoadingAgency || !agencyStatus ? (
		<LoadingPage />
	) : (
		<Route path={props.path} component={props.component} exact={props.exact} />
	);
};

export const AgencyRoute = (props: RouteProps) => {
	const { agencyStatus } = useAgencyStatus();
	const lang = BackOfficeLangFactory();
	const generalLang = GeneralLangFactory();

	const sections: MenuSection[] = !agencyStatus
		? []
		: withBooleanFilter<MenuSection>([
				{
					title: generalLang.Overview,
					icon: "overview",
					route: getRoute("agencyOverview", { agencyId: agencyStatus.Id }),
					accessRequirements: [],
					subs: [],
				},
				{
					title: lang.Clients,
					icon: "company",
					route: getRoute("agencyClients", { agencyId: agencyStatus.Id }),
					accessRequirements: [],
					subs: [
						{
							title: lang.Clients,
							route: getRoute("agencyClients", { agencyId: agencyStatus.Id }),
							accessRequirements: [],
							hidden: false,
						},
						{
							title: lang.Page_ClientRequests,
							route: getRoute("agencyClientRequests", { agencyId: agencyStatus.Id }),
							accessRequirements: [],
							hidden: !agencyStatus.Access.ManageCompanies,
							counter: AgencyCounterType.ClientRequests,
						},
						{
							title: lang.Page_QuoteRequests,
							route: getRoute("agencyQuoteRequestsList", { agencyId: agencyStatus.Id }),
							accessRequirements: [],
							hidden:
								agencyStatus.PartnerType != m.Entities.PartnerType.AgencyPartner ||
								!agencyStatus.Access.ManageQuoteRequests,
							counter: AgencyCounterType.QuoteRequests,
						},
					],
				},
				agencyStatus.Access.ManageUsers && {
					title: lang.Accountants,
					icon: "group",
					route: getRoute("agencyUsers", { agencyId: agencyStatus.Id }),
					accessRequirements: [],
					subs: [],
				},
				{
					title: lang.TasksAndComments_Title,
					icon: "todo",
					route: getRoute("agencyNotes", { agencyId: agencyStatus.Id }),
					accessRequirements: [],
					subs: [],
				},
				{
					title: lang.Scene_Settings_Title,
					icon: "settings",
					route: getRoute("agencySettings", { agencyId: agencyStatus.Id }),
					accessRequirements: [],
					subs: [],
				},
			]);
	return (
		<Layout
			menu={menuProps => (
				<div>
					<TopBar mode={TopBarMode.BackOffice} toggleMenu={menuProps.toggleMenu} />
					<Sidebar
						expanded={menuProps.expanded}
						onToggle={menuProps.toggleMenu}
						onClose={menuProps.closeMenu}
						sections={sections}
						showBookkeepingButton={false}
						isAgency={true}
					/>
				</div>
			)}
			content={<AgencyRouteContent {...props} />}
		/>
	);
};
