import Icon from "@bokio/elements/Icon/Icon";
import { useOrganisationInfo } from "@bokio/hooks/useOrganisationInfo/useOrganisationInfo";
import { GeneralLangFactory } from "@bokio/lang";

import * as styles from "./menuCurrentCompany.scss";

export interface MenuCurrentCompanyMobileProps {
	showManageCompanies: () => void;
}

export const MenuCurrentCompanyMobile = (props: MenuCurrentCompanyMobileProps) => {
	const { organisationName } = useOrganisationInfo();
	const lang = GeneralLangFactory();

	if (!organisationName) {
		return null;
	}

	return (
		<div className={styles.companySelectorMobile}>
			<span className={styles.label}>{lang.Menu_CurrentCompany}</span>
			<a onClick={props.showManageCompanies}>
				{organisationName}
				<Icon name="right-open-big" size="12" />
			</a>
		</div>
	);
};
