import { Config } from "@bokio/shared/config";

import { setAccountingLang } from "@bokio/mobile-web-shared/core/lang/AccountingLangFactory";
import { setBackOfficeLang } from "@bokio/mobile-web-shared/core/lang/BackOfficeLangFactory";
import { setBankLang } from "@bokio/mobile-web-shared/core/lang/BankLangFactory";
import { setClosuresLang } from "@bokio/mobile-web-shared/core/lang/ClosuresLangFactory";
import { setCountriesLang } from "@bokio/mobile-web-shared/core/lang/CountriesLangFactory";
import { setExpensesLang } from "@bokio/mobile-web-shared/core/lang/ExpensesLangFactory";
import { setGeneralLang } from "@bokio/mobile-web-shared/core/lang/GeneralLangFactory";
import { setInvoicesLang } from "@bokio/mobile-web-shared/core/lang/InvoicesLangFactory";
import { setOnboardingLang } from "@bokio/mobile-web-shared/core/lang/OnboardingLangFactory";
import { setPartnersLang } from "@bokio/mobile-web-shared/core/lang/PartnersLangFactory";
import { setPayslipCalculationsLang } from "@bokio/mobile-web-shared/core/lang/PayslipCalculationsLangFactory";
import { setRecipesLang } from "@bokio/mobile-web-shared/core/lang/RecipesLangFactory";
import { setSalaryLang } from "@bokio/mobile-web-shared/core/lang/SalaryLangFactory";
import { setSettingsLang } from "@bokio/mobile-web-shared/core/lang/SettingsLangFactory";
import { setSignUpLang } from "@bokio/mobile-web-shared/core/lang/SignUpLangFactory";

/*
 * Language handling hack. This is to be able to update each language without creating a dependency on all of them that will load the whole tree.
 */
export type LangListener = (lang: string) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LangLoader = (lang: string) => Promise<any>;

class LanguageNotifier {
  listeners: LangListener[] = [];
  currentLang = "";

  load = (lang: string) => {
    if (this.currentLang !== lang) {
      this.currentLang = lang;

      return Promise.all([
        setAccountingLang(lang, window.sessionStorage),
        setClosuresLang(lang, window.sessionStorage),
        setExpensesLang(lang, window.sessionStorage),
        setGeneralLang(lang, window.sessionStorage),
        setInvoicesLang(lang, window.sessionStorage),
        setPartnersLang(lang, window.sessionStorage),
        setPayslipCalculationsLang(lang, window.sessionStorage),
        setRecipesLang(lang, window.sessionStorage),
        setSalaryLang(lang, window.sessionStorage),
        setSettingsLang(lang, window.sessionStorage),
        setSignUpLang(lang, window.sessionStorage),
        setOnboardingLang(lang, window.sessionStorage),
        setCountriesLang(lang, window.sessionStorage),
        setBackOfficeLang(lang, window.sessionStorage),
        setBankLang(lang, window.sessionStorage),
      ]).then(data => {
        Config.setup({ lang });
        this.listeners.forEach(listener => listener(lang));

        return data;
      });
    }

    return Promise.resolve([]);
  };

  subscribe = (item: LangListener) => {
    this.listeners.push(item);
  };
}

export const languageNotifier = new LanguageNotifier();
