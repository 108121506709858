import * as React from "react";

import { languageNotifier } from "@bokio/lang/languageNotifier";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useActiveBankConnection } from "@bokio/mobile-web-shared/hooks/useActiveBankConnection/useActiveBankConnection";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { useCompanyUser } from "@bokio/shared/state/requests";

import { paymentContext } from "./PaymentContext";

import type { PaymentContextValue } from "./PaymentContext";

type UserPaymentPermissionDto = m.Bokio.Bank.Contract.Dtos.UserPaymentPermissionDto;
export const PaymentContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { company, companyUserPermissions } = useCompanyUser();
	// BBA multiple accounts refactoring:
	// Currently only looks at the external system in the backend,
	// but we probably want to keep checking bank account ID to allow specific rules in savings account,
	// e.g.:
	// - to allow international payments or not (should probably in the bank (bank account) config),
	// - to allow BG/PG payment or not,
	// - to allow to order card or not
	// - to decide should you be able to make supplier/salary/expense/tax... payments.
	const { activeBusinessAccount } = useActiveBankConnection();
	const [currentUserPermission, setCurrentUserPermission] = React.useState<UserPaymentPermissionDto>({
		CanSignPayment: false,
	});

	// Using ref lock because request.isLoading is updated by setState,
	// which means it will stay false before state updates are applied.
	// Therefore if we only do `const refresh = () => !isLoading && execute();`,
	// the execute might be called multiple times if there are multiple calls to the refresh.
	// (i.e. if there are multiple components getting the same request from context, for example `CreatePaymentScene`.)
	const countryListRequestLock = React.useRef(false);
	const currencyListRequestLock = React.useRef(false);

	const [executeCountryListRequest, countryListRequest] = useLazyApi(
		proxy.Bank.LocaleInfoController.GetCountryList.Get,
	);
	const [executeCurrencyList, currencyListRequest] = useLazyApi(proxy.Bank.LocaleInfoController.GetCurrencyList.Get);
	const [getPermissionOfCurrentUser, getPermissionOfCurrentUserRequest] = useLazyApi(
		proxy.Bank.PaymentController.GetPermissionOfCurrentUser.Get,
	);

	const [signablePaymentCount, setSignablePaymentCount] = React.useState(0);
	const [executeGetSignablePaymentCount] = useLazyApi(proxy.Bank.PaymentController.GetSignablePaymentCount.Get, {
		onSuccess: setSignablePaymentCount,
	});

	const companyId = company?.Id;
	const userHasUploadBankPermission = !!companyUserPermissions?.UploadBank;
	const userHasCompanySettingsPermission = !!companyUserPermissions?.CompanySettings;
	const bankAccountId = activeBusinessAccount?.BankAccountId;

	const refreshSignablePaymentCount = React.useCallback(() => {
		// The CanSignPayment permission check can be removed as nothing in the backend blocks the called endpoint
		// It's here right now as it's only the signee who uses the count
		if (currentUserPermission.CanSignPayment && userHasUploadBankPermission && companyId) {
			return executeGetSignablePaymentCount(companyId);
		} else {
			return undefined;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUserPermission.CanSignPayment, companyId, userHasUploadBankPermission]);

	React.useEffect(() => {
		refreshSignablePaymentCount();
	}, [refreshSignablePaymentCount]);

	const refreshPaymentPermission = React.useCallback(async () => {
		if (userHasUploadBankPermission && companyId) {
			const res = await getPermissionOfCurrentUser(companyId);
			if (res.Data) {
				setCurrentUserPermission(res.Data);
			}
		}
	}, [companyId, userHasUploadBankPermission, getPermissionOfCurrentUser]);

	const [getPaymentValidationRules, getPaymentValidationRequest] = useLazyApi(
		proxy.Bank.PaymentController.GetPaymentValidationRules.Get,
	);

	// TODO Refresh paymentValidationRules at midnight because next
	// possible banking day will change.
	const refreshPaymentValidationRules = React.useCallback(async () => {
		if (userHasUploadBankPermission && companyId && bankAccountId) {
			getPaymentValidationRules(companyId, bankAccountId);
		}
	}, [userHasUploadBankPermission, companyId, getPaymentValidationRules, bankAccountId]);

	const [getMirOnboardingStatus, mirOnboardingStatusRequest] = useLazyApi(
		proxy.Bank.MirOnboardingController.OnboardingStatus.Get,
	);

	const refreshMirOnboardingStatus = React.useCallback(async () => {
		if (userHasCompanySettingsPermission && companyId) {
			getMirOnboardingStatus(companyId);
		}
	}, [userHasCompanySettingsPermission, companyId, getMirOnboardingStatus]);

	const refreshCountryListIfNeeded = async (force?: boolean) => {
		if ((force || !countryListRequest.data?.Data) && !countryListRequestLock.current && companyId) {
			countryListRequestLock.current = true;
			try {
				await executeCountryListRequest(companyId, {
					Consumer: m.Bokio.Bank.Contract.Utils.CountryListConsumer.Payment,
				});
			} finally {
				countryListRequestLock.current = false;
			}
		}
	};

	const refreshCurrencyListIfNeeded = async (force?: boolean) => {
		if ((force || !currencyListRequest.data?.Data) && !currencyListRequestLock.current && companyId) {
			currencyListRequestLock.current = true;
			try {
				await executeCurrencyList(companyId);
			} finally {
				currencyListRequestLock.current = false;
			}
		}
	};

	React.useEffect(() => {
		refreshPaymentPermission();
	}, [refreshPaymentPermission]);

	React.useEffect(() => {
		refreshPaymentValidationRules();
	}, [refreshPaymentValidationRules]);

	React.useEffect(() => {
		refreshMirOnboardingStatus();
	}, [refreshMirOnboardingStatus]);

	languageNotifier.subscribe(() => {
		refreshCountryListIfNeeded(true);
		refreshCurrencyListIfNeeded(true);
	});

	class Value implements PaymentContextValue {
		get currentUserPermission() {
			return currentUserPermission;
		}
		get refreshPaymentPermission() {
			return refreshPaymentPermission;
		}
		get paymentValidationRulesRequest() {
			return getPaymentValidationRequest;
		}
		get paymentValidationRules() {
			return getPaymentValidationRequest.data?.Data;
		}
		get getPermissionOfCurrentUserRequest() {
			return getPermissionOfCurrentUserRequest;
		}
		// Defer getting these because they are only used in very few places
		get countryListRequest() {
			refreshCountryListIfNeeded();
			return countryListRequest;
		}
		get currencyListRequest() {
			refreshCurrencyListIfNeeded();
			return currencyListRequest;
		}
		get signablePaymentCount() {
			return signablePaymentCount;
		}
		get refreshSignablePaymentCount() {
			return refreshSignablePaymentCount;
		}
		get mirOnboardingStatus() {
			return mirOnboardingStatusRequest.data?.Data;
		}
		get mirOnboardingStatusRequest() {
			return mirOnboardingStatusRequest;
		}
		get refreshMirOnboardingStatus() {
			return refreshMirOnboardingStatus;
		}
	}

	return <paymentContext.Provider value={new Value()}>{children}</paymentContext.Provider>;
};
