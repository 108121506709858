import { connect } from "react-redux";

import { getCompanyUser as getCompanyInfo } from "@bokio/shared/state/requests";

import type { State } from "../../state/state";
import type * as m from "@bokio/mobile-web-shared/core/model/model";

export interface CompanyInfoProps {
	// Beware this could actually be `undefined`
	// TODO: Fix typing here
	companyInfo: m.Core.CompanyInfo;
}

export interface CompanyInfoPropsFromDispatch {
	reloadCompanyInfo: () => void;
}

// TODO: Move to user's folder scope
export const mapStateToProps = (state: State): CompanyInfoProps => {
	const getCompanyUser = state.requests.getCompanyUser;
	return {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		companyInfo: (getCompanyUser.data && getCompanyUser.data.Company)!,
	};
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const mapDispatchToProps = (dispatch: Function): CompanyInfoPropsFromDispatch => ({
	reloadCompanyInfo: () => dispatch(getCompanyInfo.execute()),
});

/**
 * @deprecated prefer `useCompanyInfo` hook.
 */
const withCompanyInfo = connect(mapStateToProps, {}) as <OwnProps>(
	component: React.ComponentType<OwnProps & CompanyInfoProps>,
) => React.ComponentType<OwnProps>;

export default withCompanyInfo;
